<template>
    <div class="content">
        <div class="bottom">
            <!-- 底部栏 -->
            <div class="item-1">
                <h1>
                    关于华亿康
                </h1>
                <h2>潍坊华亿康医疗设备公司位于潍坊高新区，自2018年起集研发、产销、代理输血医疗设备于一体，专注血站、医院输血科产品，涵盖血浆解冻箱、血小板保存箱等。<br>同时为科研单位提供净化操作台等设备。诚邀各地代理商和用户合作。
                </h2>
                <a href="https://beian.miit.gov.cn/#/Integrated/index">鲁ICP备2024072757号-5
                </a>
            </div>
            <div class="item-2">
                <h1>与我们联系</h1>
                <span>公司：{{ jsondata.config.gongsi }}</span>
                <span>电话：{{ jsondata.config.tel }}</span>
                <span>座机：{{ jsondata.config.tel2 }}</span>
                <span>售后：{{ jsondata.config.shouhou }}</span>
                <span>地址：{{ jsondata.config.dizhi }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import HomeViewJson from '../views/data/HomeView.json'
export default ({
    data() {
    return {
      //
      jsondata:HomeViewJson
    }}
});
</script>
<style scoped src="./style/BottomBar/BottomBar.min.css"></style>

<style scoped>
a{
    color: white;
}</style>
<template>
  <div class="content">
    <!-- 主页 pc -->
    <div class="content-lunbo ">
      <el-carousel height="800px">
        <el-carousel-item v-for="(item, i) in data.scorll1" :key="i">
          <div class="lunbo-content">
            <!-- 左侧 -->
            <div class="top-left">
              <h1>{{ item.title }}</h1>
              <h2 v-for="(item2, i) in item.data" :key="i">{{ item2 }}</h2>
            </div>
            <!-- 右侧 -->
            <div class="top-right" :style="{ 'background-image': `url(${item.path})` }">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- 手机 -->
    </div>
    <div class="content-lunbo-phone">
      <el-carousel height="400px">
        <el-carousel-item v-for="(item, i) in data.scorll1" :key="i">
          <div class="lunbo-content">
            <!-- 左侧 -->
            <div class="top-left">
              <h1>{{ item.title }}</h1>
              <h2 v-for="(item2, i) in item.data" :key="i">{{ item2 }}</h2>
            </div>
            <!-- 右侧 -->
            <div class="top-right" :style="{ 'background-image': `url(${item.path})` }">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 主推产品 -->
    <!-- <div class="ace-product">
      <div class="top-content">
        <div class="left-content">
          <span>热销产品</span>
        </div>
      </div>
      <div class="content">
        <div>
          <span>血液低温操作台</span>
          <h1></h1>
          <h2></h2>
          <h3></h3>
          <h4></h4>
        </div>
        <div></div>
      </div>
    </div> -->
    <!-- 产品中心 PC -->
    <div class="products">
      <!-- 顶部栏 -->
      <div class="top-bar">
        <div class="left-content">
          <span>系列产品</span>
        </div>
        <!-- 内容 -->
        <div class="top-bar-content">
          <span v-for="(item, i) in prodata" :key="i" @click="gotoCheck(item.id)">{{ item.text }}</span>
        </div>
        <div class="right-content" @click="goToproducts()">
          查看更多产品->>
        </div>
      </div>
      <!-- 内容栏 -->
      <div class="content">
        <!--  -->
        <div class="item-content">
          <div class="item" v-for="(item, i) in prodata" :key="i" @click="gotoCheck(item.id)">
            <div class="item-img" :style="{ 'background-image': `url(${item.path})` }">
            </div>
            <h1>
              {{ item.text }}
            </h1>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品中心 手机 -->
    <div class="products-phone">
      <div class="title">
        <span>产品系列</span>
      <h2 @click="goToproducts()">查看全部产品->></h2>
      </div>
      <div class="content">
        <!--  -->
        <div class="item-content">
          <div class="item" v-for="(item, i) in prodata" :key="i" @click="gotoCheck(item.id)">
            <div class="item-img" :style="{ 'background-image': `url(${item.path})` }">
            </div>
            <h1>
              {{ item.text }}
            </h1>
          </div>
        </div>
      </div>
    </div>
    <!-- 更底部内容 -->
    <div class="news-content">
      <!-- 内容栏 -->
      <!-- <div class="top-bar"> -->
      <!-- <span>新闻中心</span>
        <h1>news</h1> -->
      <!-- </div> -->
    </div>
  </div>
</template>
<style scoped src="./style/HomeView/HomeView.css"></style>
<style></style>
<script>
import HomeData from './data/HomeView.json'
export default ({
  data() {
    return {
      data: HomeData,
      prodata: HomeData.scorll2,
    };
  },
  methods: {
    goToproducts() {
      console.log('到')
      this.$router.push("/products/0")
    },
    // 跳转产品详情
    gotoCheck(code) {
      console.log("code", code)
      this.$router.push('/products/' + code)
      window.scrollTo({
        top: 0
      });
    },
    goToMesh(path) {
      console.log("路由地址", this.$route.path);
      if (this.$route.path == path) {
        console.log("禁止跳转");
      } else {
        this.$router.push(path)
        window.scrollTo({
          top: 0
        });
      }
    }

  },

});
</script>

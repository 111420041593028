<template>
  <div id="app">
    <topBar></topBar>
    <div style="margin-bottom: 90px;"></div>
    <div class="top-content-router-view"><router-view /></div>
    <BottomBar></BottomBar>

  </div>
</template>

<style>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none; /* 去除默认的下划线 */
	outline: none;	/* 去除旧版浏览器的点击后的外虚线框 */
	color: #000;	/* 去除默认的颜色和点击后变化的颜色 */ 
}
h1, h2, h3, h4, h5, h6, h7 {  
    margin: 0; /* 移除默认的边距 */  
    padding: 0; /* 移除默认的内边距 */  
    font-weight: normal; /* 设置为正常字体粗细 */  
    color: inherit; /* 继承父元素的字体颜色 */  
    line-height: 1.2; /* 设置行高为1.2倍字体大小 */  
    font-size: 100%; /* 设置字体大小为100%，即继承父元素的字体大小 */  
}  
img {  
    margin: 0; /* 移除外边距 */  
    padding: 0; /* 移除内边距 */  
    border: none; /* 移除边框 */  
    box-sizing: border-box; /* 边框和填充包含在宽度和高度内 */  
    vertical-align: top; /* 防止基线对齐造成的间隙 */  
    /* 其他你希望重置的属性 */  
}
</style>

<style scoped>
  .top-content-router-view {
    max-width: 100vw;
  }
</style>
<script>
import topBar from "./components/TopBar.vue";
import BottomBar from "./components/BottomBar.vue";

export default {
  components: { topBar, BottomBar }
} 
</script>
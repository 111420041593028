import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    // 首页
    path: '/',
    name: 'home',
    component: HomeView
  }, {
    // 产品中心
    name: 'products',
    path: "/products/:chese",
    component: () => import('../views/ProductsView.vue')
  }, {
    // 产品详情
    name: 'prp',
    path: "/prp/:code",
    component: () => import('../views/ProductContentView.vue')
  }, {
    // 关于我们
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  }, {
    // 联系我们
    path: '/telme',
    name: 'telme',
    component: () => import('../views/TelmeView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
    <div class="content">
        <!-- 手机端显示 -->
        <div class="phone">
            <div class="top-bar">
                <!-- 左侧按钮 -->
                <div class="top-btn" @click="show1 = !show1">
                    <el-button class="el-icon-s-grid"></el-button>
                </div>
                <!-- logo 容器 -->
                <div class="logo">
                </div>
                <!-- 桌面版 -->
            </div>
            <!-- 用于控制下部元素 -->
            <div class="top-content">
                <el-collapse-transition>
                    <div v-show="show1">
                        <div class="top-content-item" style="cursor: pointer;"
                            @click="show1 = !show1, goToMesh(item.path)" v-for="(item, i) in top_data_json" :key="i">{{
                    item.name }}
                        </div>
                    </div>
                </el-collapse-transition>
            </div>
        </div>
        <!-- 电脑端显示 -->
        <div class="computer">
            <div class="computer-content">
                <!-- logo -->
                <div class="logo">
                </div>
                <!-- 右侧 -->
                <div class="right">
                    <!-- 用于子元素渲染 -->
                    <div class="item-top-btn" v-for="(item, i) in  top_data_json" :key="i" @click="goToMesh(item.path)">
                        {{ item.name }}
                    </div>
                    <!-- 下部悬浮窗 -->
                    <el-collapse-transition>
                        <div class="item-content" >
                            <div class="item-content-btn" v-for="(item, i) in pathData" :key="i">{{ item.title }}</div>
                        </div>
                    </el-collapse-transition>

                </div>
            </div>
        </div>
    </div>
    <!-- 用于数据展示 -->


</template>
<script>
export default {
    data: () => ({
        show1: false,
        show2: false,
        base: "0",
        top_data_json: [
            {
                path: '/',
                name: '首页'
            }, {
                path: '/about',
                name: '关于华亿康'
            }, {
                path: '/products/0',
                name: '产品中心'
            }, {
                path: '/telme',
                name: '联系我们'
            }],
        pathData: [
            {
                chese: "100",
                title: "血液低温操作台"
            }, {
                chese: "300",
                title: "血小板保存箱"
            }, {
                chese: "200",
                title: "血液低温配血台"
            }, {
                chese: "400",
                title: "数码恒温解冻箱"
            }, {
                chese: "500",
                title: "血液低温滤白柜"
            }, {
                chese: "600",
                title: "血液运输箱系列"
            }, {
                chese: "700",
                title: "医用高频热合机"
            }
        ]
    }),
    methods: {
        goToMesh(path) {
            console.log("路由地址", this.$route.path);
            // 如果地址等于当前路由地址, 则不跳转
            console.log("path", path)
            if (this.$route.path === path) {
                console.log("禁止跳转");
                return
            } else {
                this.$router.push(path)

                // 如果地址等于/产品/100
                if (this.$route.path == "/products/100") {
                    this.base = "100"
                }
            }
        },
        goToWeb(id) {
            console.log("缓存，直接传值", this.base, id);
            if (id !== this.base) {
                this.base = id;
                this.$router.push("/products/" + id);
            } else {
                console.log("错误");
            }
        },
    },

}
</script>
<style scoped src="./style/TopBar/TopBar.min.css"></style>